// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Open Sans';
$font-size-base: 0.9rem;
$line-height-base: 1.6;


$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #f7f7f9;
$gray-300: #eceeef;
$gray-400: #ced4da;
$gray-500: #aaa;
$gray-600: #888;
$gray-700: #5a5a5a;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #FF7851;
$orange: #fd7e14;
$yellow: #FFCE67;
$green: #56CC9D;
$teal: #20c997;
$cyan: #6CC3D5;
$primary: #94ce42;
$secondary: rgb(196, 105, 109);
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;
$body-color: $gray-600;
$headings-color: $gray-700;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $secondary;
$navbar-dark-color: rgba($white,.6);
$navbar-dark-hover-color: $white;
$border-radius: 0;
